const generalTags = [
  'Two Hander',
  'Actively Staffing',
];

const genres = [
  'Genre - Action',
  'Genre - Action-Adventure',
  'Genre - Action - Comedy',
  'Genre - Biopic',
  'Genre - Black Comedy',
  'Genre - Comedy',
  'Genre - Concert Film',
  'Genre - Crime',
  'Genre - Documentary',
  'Genre - Drama',
  'Genre - Dramedy',
  'Genre - Educational',
  'Genre - Family',
  'Genre - Fantasy',
  'Genre - Horror',
  'Genre - Music',
  'Genre - Musical',
  'Genre - Mystery',
  'Genre - Noir',
  'Genre - Period',
  'Genre - Reality Based',
  'Genre - Remake',
  'Genre - Romantic',
  'Genre - Romantic Comedy',
  'Genre - Science Fiction',
  'Genre - Sports',
  'Genre - Tentpole',
  'Genre - Thriller',
  'Genre - Unknown',
  'Genre - Western',
  'Genre - Young Adult',
];

const features = [
  ...generalTags,
  ...genres,
  'Indie',
  'Indie Priority',
  'Indie - With Int’l Sales Agent',
  'Indie - Without Int’l Sales Agent',
  'INDIE: Hot Sheet - T1',
  'INDIE: Hot Sheet - T2',
  'INDIE: Hot Sheet - T3',
  'INDIE: Hot Sheet - T4',
  'INDIE: Hot Sheet - T5',
  'Indie Meeting',
  'Medium - 3-D',
  'Medium - Animation',
  'Medium - CGI/Live Action',
  'Medium - Direct To DVD',
  'Medium - Film',
  'MP: Hot Sheet - T1',
  'MP: Hot Sheet - T2',
  'MP: Hot Sheet - T3',
  'MP: Hot Sheet - T4',
  'MP: Hot Sheet - T5',
  'MP: Literary Status - Chinese Language',
  'MP: Literary Status - Chinese OC',
  'MP: Literary Status - Chinese OD',
  'MP: Literary Status - Chinese OW',
  'MP: Literary Status - In Development',
  'MP: Literary Status - In Post Production',
  'MP: Literary Status - In Production',
  'MP: Literary Status - In Revisions',
  'MP: Literary Status - In Soon',
  'MP: Literary Status - In Turnaround',
  'MP: Literary Status - Indie OC',
  'MP: Literary Status - OC',
  'MP: Literary Status - OC:Track For',
  'MP: Literary Status - OD',
  'MP: Literary Status - OD:Development Deal',
  'MP: Literary Status - OD:Track For',
  'MP: Literary Status - On Hold',
  'MP: Literary Status - OW',
  'MP: Literary Status - OW:Adaptation',
  'MP: Literary Status - OW:Polish',
  'MP: Literary Status - OW:Prod. Polish (Weekly)',
  'MP: Literary Status - OW:Rewrite',
  'MP: Literary Status - OW:Track For',
  'MP: Literary Status - Pre-Production',
  'MP: Literary Status - Priority',
  'MP: Literary Status - Priority IP',
  'MP: Literary Status - Priority OC',
  'MP: Literary Status - Priority OD',
  'MP: Literary Status - Priority OW',
  'MP: Literary Status - Wednesday OD Meeting',
  'MP: Talent Status - Active',
  'MP: Talent Status - Actively Casting',
  'MP: Talent Status - Active Voiceover',
  'MP: Talent Status - Combined Grid',
  'MP: Talent Status - Comedy',
  'MP: Talent Status - Focused Casting',
  'MP: Talent Status - Hiatus',
  'MP: Talent Status - Pending Assignments',
  'MP: Talent Status - Unassigned Indies',
  'MP: Talent Status - Wed Talent Grid',
  'MP: Talent Status - Studio Grid',
  'MP: Talent Status - Indie Grid',
  'Original Format - Article',
  'Original Format - Documentary',
  'Original Format - Graphic Novel',
  'Original Format - Novel',
  'Original Format - Pitch',
  'Original Format - Remake',
  'Original Format - Spec',
  'Original Format - Treatment',
  'Original Format - Video Game',
  'TV: Development Stage - Development - 1 Hour',
];

const unscripted = [
  ...generalTags,
  ...genres,
  'Indie',
  'Indie Priority',
  'Indie - With Int’l Sales Agent',
  'Indie - Without Int’l Sales Agent',
  'Unscripted: Client Pitch - Non-UTA Client',
  'Unscripted: Client Pitch - UTA Client',
  'Unscripted: Development Stage - Pilot',
  'Unscripted: Development Stage - Pre-Production',
  'Unscripted: Development Stage - Presentation',
  'Unscripted: Development Stage - Series',
  'Unscripted: Development Stage - Series in Production',
  'Unscripted: Series Commitment - 1 episode',
  'Unscripted: Series Commitment - 10 episodes',
  'Unscripted: Series Commitment - 12 episodes',
  'Unscripted: Series Commitment - 13 episodes',
  'Unscripted: Series Commitment - 2 episodes',
  'Unscripted: Series Commitment - 20 episodes',
  'Unscripted: Series Commitment - 6 episodes',
  'Unscripted: Series Commitment - 8 episodes',
  'Unscripted: Show Format - 30 Minute',
  'Unscripted: Show Format - 60 Minute',
  'Unscripted: Status - Closed Casting',
  'Unscripted: Status - Closed Staffing',
  'Unscripted: Status - Open Casting',
  'Unscripted: Status - Open Staffing',
  'Unscripted: Talent Status - Actively Casting',
  'Unscripted: Talent Status - Cast Contingent',
  'Unscripted: Marketing - Open Marketing',
];

const television = [
  ...generalTags,
  '1 Hour',
  '1/2 Hour',
  'Anthology',
  'Development Commitment: Script',
  'Development Commitment: Series',
  'Comedy',
  'Drama',
  'Dramedy',
  'TV Tracking',
  'TV Tracking Priorities',
  'Script on File: No',
  'Script on File: Yes',
  'Limited Series',
  'Actively Casting',
  'Cast Contingent',
  'Tuesday Grid Longform',
  'Wednesday Grid',
  'On Air',
  'Open Showrunning',
  'Original Format: Novel',
  'Original Format: Podcast',
  'Pilot Pickup',
  'Pilot Production Commitment',
  'Put Pilot',
  'Priority OWA',
  'Priority ODA',
  'Script + Penalty',
  'Script to Series',
  'Short Format',
  'Backdoor Pilot',
  'Pilot Presentation',
  'Open Directing',
  'Staffing',
  'Open Writing',
  'Episodes: 1',
  'Episodes: 2',
  'Episodes: 3',
  'Episodes: 4',
  'Episodes: 5',
  'Episodes: 6',
  'Episodes: 7',
  'Episodes: 8',
  'Episodes: 9',
  'Episodes: 10',
  'Episodes: 11',
  'Episodes: 12',
  'Episodes: 13',
  'Episodes: 14',
  'Episodes: 15',
  'Episodes: 16',
  'Episodes: 17',
  'Episodes: 18',
  'Episodes: 19',
  'Episodes: 20',
  'Episodes: 21',
  'Episodes: 22',
  'Episodes: 23',
  'Episodes: 24',
  'Episodes: 25',
  'Sketch / Variety',
  'Hybrid',
  'Multi Camera',
  'Single Camera',
  'Tuesday Grid Series',
  'FileMaker Pro',
  'TV: Talent Status - Active Voiceover',
  'Medium - Animation',
  'Recurring Document',
  'Script on File: Yes, Confidential',
  'Ongoing Series',
];

const theatre = [
  ...generalTags,
  'Genre - Comedy',
  'Genre - Drama',
  'Genre - Unknown',
  'Theatre: Contracts - Broadway',
  'Theatre: Contracts - International',
  'Theatre: Contracts - Off Broadway',
  'Theatre: Contracts - Readings/Workshops',
  'Theatre: Contracts - Regional',
  'Theatre: Contracts - West End',
  'Theatre: Form - Musical',
  'Theatre: Form - Play',
  'Theatre: Status - Active',
  'Theatre: Status - In Development',
  'Theatre: Status - Inactive',
  'Theatre: Status - Pending Assignment',
  'Theatre: Status - Script',
  'Theatre: Status - Theatre Meeting',
  'Theatre: Type - Commercial',
  'Theatre: Type - Non Profit',
];

export const readOnlyProfiles = ['FileMaker Pro'];

export const profilesByType = {
  Feature: features,
  Television: television,
  Unscripted: unscripted,
  Theatre: theatre,
};

export function getProfilesByType(type, options = {}) {
  var profiles =
    (type && profilesByType[type]) || Object.values(profilesByType).reduce((acc, profiles) => acc.concat(profiles), []);
  if (options.excludeReadOnly) {
    profiles = profiles.filter(profile => !readOnlyProfiles.includes(profile));
  }
  return exportFunctions.profileOptions(profiles);
}

export function isProfileReadOnly(type, _readOnlyProfiles = readOnlyProfiles) {
  return _readOnlyProfiles.includes(type);
}

const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });

export function naturalSort(a, b) {
  return collator.compare(a, b);
}

export function profileOptions(profiles) {
  return profiles.sort(exportFunctions.naturalSort).map(profile => {
    return { value: profile, label: profile };
  });
}

export function loadProfiles(types = [], value) {
  let profiles = [];
  if (types && types.length > 0) {
    for (const type of types) {
      profiles.push(...(profilesByType[type] || []));
    }
  } else {
    profiles.push(...Object.values(profilesByType).reduce((acc, profiles) => acc.concat(profiles), []));
  }

  return Array.from(new Set(profiles))
    .sort(exportFunctions.naturalSort)
    .filter(profile => profile.toLowerCase().includes(value ? value.toLowerCase() : ''))
    .map(value => {
      return { name: value };
    });
}

const exportFunctions = {
  getProfilesByType,
  profileOptions,
  naturalSort,
};

export default exportFunctions;
